<template>
  <!-- 解决方案详情 -->
  <div class="solution">
    <div class="banner" v-if="areaObj.solutionPcImg">
      <img
        :src="areaObj.solutionPcImg ? areaObj.solutionPcImg : ''"
        v-if="$store.state.pageset.pcshow"
      />
      <img
        :src="areaObj.solutionPhoneImg ? areaObj.solutionPhoneImg : ''"
        v-else
      />
      <div class="dec" v-animate="'queue-bottom'">
        <p class="p1">{{ areaObj.solutionName }}</p>
        <p class="p2">
          {{ areaObj.solutionDescription }}
        </p>
      </div>
    </div>
    <!-- 内容 -->
    <div
      class="solution-content"
      v-if="areaDetailObj.webControlSolutionEquipmentList"
    >
      <!-- 核心设备 -->
      <p class="content-title">Core equipment</p>
      <div class="content-device">
        <div
          v-for="(item, index) in areaDetailObj.webControlSolutionEquipmentList"
          :key="index"
          class="content-device-item"
        >
          <div class="case-img">
            <img
              :src="item.equipmentPcImg ? item.equipmentPcImg : ''"
              v-if="$store.state.pageset.pcshow"
            />
            <img
              :src="item.equipmentPcImg ? item.equipmentPhoneImg : ''"
              v-else
            />
          </div>
          <div class="content-device-dec">
            <p class="p1" v-animate="'queue-bottom'">
              {{ item.equipmentName }}
            </p>
          </div>
          <div class="content-device-remark" v-if="item.remark">
            <p v-animate="'queue-bottom'">
              {{ item.remark }}
            </p>
          </div>
        </div>
      </div>
      <!-- 痛点问题 -->
      <p class="content-title">Pain point problem</p>
      <div class="content-pain">
        <div
          v-for="(item, index) in areaDetailObj.webControlSolutionPainList"
          :key="index"
          class="content-pain-item"
        >
          <div class="content-pain-item-con">
            <img
              :src="item.painPcImg ? item.painPcImg : ''"
              v-if="$store.state.pageset.pcshow"
            />
            <img :src="item.painPcImg ? item.painPhoneImg : ''" v-else />
            <div class="content-pain-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ item.painName }}</p>
              <p class="p2">{{ item.painDescription }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 解决方案 -->
      <p class="content-title">Solution</p>
      <div class="content-programme">
        <div
          v-for="(item, index) in areaDetailObj.webControlSolutionCaseList"
          :key="index"
          class="content-programme-item"
        >
          <div class="programme-img">
            <img
              :src="item.solutionCasePcImg ? item.solutionCasePcImg : ''"
              v-if="$store.state.pageset.pcshow"
            />
            <img
              :src="item.solutionCasePhoneImg ? item.solutionCasePhoneImg : ''"
              v-else
            />
          </div>
          <div class="content-programme-dec">
            <p class="p1" v-animate="'queue-bottom'">
              {{ item.solutionCaseName }}
            </p>
          </div>
        </div>
      </div>

      <!-- 更多解决方案 -->
      <p class="content-title">More solutions</p>
      <div class="content-device more-solution" v-if="solutionList.length">
        <div v-for="index in 3" :key="index" class="content-device-item">
          <div
            class="case-img"
            v-if="index"
            @click.stop="solutiondetails(solutionList[randomList[index - 1]])"
          >
            <img
              :src="
                solutionList[randomList[index - 1]].solutionPcImg
                  ? solutionList[randomList[index - 1]].solutionPcImg
                  : ''
              "
              v-if="$store.state.pageset.pcshow"
            />
            <img
              :src="
                solutionList[randomList[index - 1]].solutionPhoneImg
                  ? solutionList[randomList[index - 1]].solutionPhoneImg
                  : ''
              "
              v-else
            />
          </div>
          <div class="content-device-dec">
            <p class="p1" v-animate="'queue-bottom'">
              {{ solutionList[randomList[index - 1]].solutionName }}
            </p>
            <p class="p2" v-animate="'queue-bottom'">
              Learn more<i class="el-icon-arrow-right"></i>
            </p>
          </div>
        </div>
      </div>

      <!-- <div class="order">
        <el-button size="small" class="btn-div-experience" plain @click="retention"
          >预约体验</el-button
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import solutionAreaDetail_en from "./solutionAreaDetail_en.js";

export default solutionAreaDetail_en;
</script>
<style lang="scss" scoped>
@import "~@/assets/style/common.scss";
@import "../../../commonStyle/solutionDetail.scss";
.banner {
  .dec {
    display: block;
    color: white;
    padding: 10px 150px;
    .p1 {
      font-size: 30px;
      font-family: "Microsoft YaHei";
    }
  }
}
.content-pain-dec {
  .p1,
  .p2 {
    flex-wrap: wrap;
    flex-direction: row;
    flex-shrink: 3;
  }
}

.content-programme {
  .content-programme-item {
    .content-programme-dec {
      min-height: 150px;
      p {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
      }
    }
  }
}
</style>
